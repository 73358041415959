import * as d3 from 'd3';
import React, { FC, useEffect, useMemo, useRef } from 'react';

import {
  IndexedTsPoint,
  SpxTsData,
} from '../../charts/band-chart/BandChart.types';
import * as S from './ChartXAxis.styles';

export interface ChartXAxisProps {
  tsData: IndexedTsPoint[] | SpxTsData[];
  xScale: d3.ScaleLinear<number, number>;
  yScale: d3.ScaleLinear<number, number>;
  padding?: number;
  offsetY?: number;
  domainLimited?: boolean;
  showLine?: boolean;
  y1?: number;
  adjustedWidth?: number;
  isBordered?: boolean;
}

const MIN_X_PADDING = 10;
const TICK_STUB_SIZE = 10;
const INNER_TICK_STUB = 4;
const MONTH_STEP = 1;

const reduceDateRange = (
  datesRange: Date[],
  dateScale: d3.ScaleTime<number, number>
) => {
  const [minX, maxX] = dateScale.range();

  return datesRange.reduce<Date[]>((dateRange, date) => {
    const x = dateScale(date);

    if (minX + x <= MIN_X_PADDING || maxX - x <= MIN_X_PADDING) {
      return dateRange;
    }

    return [...dateRange, date];
  }, []);
};

export const ChartXAxis: FC<ChartXAxisProps> = ({
  tsData,
  xScale,
  yScale,
  padding = 0,
  domainLimited = false,
  showLine = true,
  y1 = 0,
  adjustedWidth = 0,
  isBordered,
}) => {
  const axisRef = useRef<SVGGElement>(null);

  const datesTotalDomain = useMemo(
    () => [tsData[0].date, tsData[tsData.length - 1].date],
    [tsData]
  );

  const yTranslate = Math.max(0, yScale.range()[0] + padding);

  const datesSixMonthDomain = useMemo(() => {
    const d = new Date(tsData[tsData.length - 1].date);
    d.setMonth(d.getMonth() - 6);
    return [d, tsData[tsData.length - 1].date];
  }, [tsData]);

  const datesDomain = domainLimited ? datesSixMonthDomain : datesTotalDomain;

  const datesRange = useMemo(() => {
    const start = datesDomain[0];
    const end = datesDomain[1];
    return d3.timeMonths(start, end, MONTH_STEP);
  }, [datesDomain]);

  const dateScale = useMemo(
    () => d3.scaleTime().domain(datesDomain).range(xScale.range()),
    [datesDomain, xScale]
  );

  useEffect(() => {
    if (axisRef.current && dateScale.range()[1] > 0) {
      const axisSelection = d3.select(axisRef.current);
      const tickValues = reduceDateRange(datesRange, dateScale);
      const axis = showLine
        ? d3.axisBottom<Date>(dateScale).tickSizeInner(TICK_STUB_SIZE)
        : d3.axisTop<Date>(dateScale).tickSizeInner(INNER_TICK_STUB);

      axis
        .tickValues(tickValues)
        .tickSizeOuter(0)
        .tickPadding(8)
        .tickFormat(d3.timeFormat('%b'));

      axisSelection.call(axis);
    }
  }, [datesRange, dateScale, axisRef, showLine]);

  return (
    <>
      {showLine &&
        datesRange.map(date => {
          const dateXPlot = dateScale(date);
          return (
            <g key={dateXPlot} transform={`translate(0, 0)`}>
              <S.AxisLine
                data-testid="x-axis-line"
                y1={y1}
                x1={dateXPlot}
                y2={yScale(yScale.domain()[0]) + TICK_STUB_SIZE + padding}
                x2={dateXPlot}
              />
            </g>
          );
        })}

      {isBordered && (
        <>
          <S.AxisBorderLine
            x1={0}
            x2={adjustedWidth}
            y1={yScale(yScale.domain()[0]) + TICK_STUB_SIZE + padding}
            y2={yScale(yScale.domain()[0]) + TICK_STUB_SIZE + padding}
          />

          <S.AxisBorderLine
            x1={0}
            x2={0}
            y1={y1}
            y2={yScale(yScale.domain()[0]) + TICK_STUB_SIZE + padding}
          />
        </>
      )}

      <S.ChartAxisG
        $showLine={showLine}
        ref={axisRef}
        data-testid="x-axis"
        transform={`translate(0, ${yTranslate})`}
      />
    </>
  );
};
