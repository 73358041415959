import { isWithinInterval } from 'date-fns/isWithinInterval';

import { config } from '~/config';
import { create } from '~/stores/create-store/createStore';
import { apiFetch } from '~/utils/api-fetch/apiFetch';

export enum SupportedLanguages {
  EN = 'en',
  JA = 'ja',
  PT = 'pt',
  KO = 'ko',
}

interface FlagsLanguageEnabled {
  enabled: boolean;
}

export interface FlagsMaintenance {
  from: string;
  to: string;
}

export type FlagsLanguage = {
  [key in SupportedLanguages]?: FlagsLanguageEnabled;
};

export interface SystemFlags {
  maintenance?: FlagsMaintenance;
  languages?: FlagsLanguage;
  feature_web: FeatureFlag[];
}

const flagsEndpoint = `system/flags/v1`;

export type FeatureFlag =
  | 'doc-filters'
  | 'portfolio'
  | 'enable_reasoner'
  | 'linking-tools'
  | 'sample';

interface SystemFlagsStore {
  systemFlags: Omit<SystemFlags, 'feature_web'>;
  featureFlags: FeatureFlag[];
  initialise(): Promise<void>;
  checkIsInMaintenance(): boolean;
  addFlags(flags: FeatureFlag[]): void;
}
const configFlags = config.app.featureFlags;
export const useFlags = create<SystemFlagsStore>((set, get) => ({
  systemFlags: {},
  featureFlags: configFlags,
  addFlags: flags => {
    set(state => ({
      featureFlags: [...state.featureFlags, ...flags],
    }));
  },
  initialise: async () => {
    const systemFlags = await apiFetch<SystemFlags>(flagsEndpoint);

    if (!systemFlags.error) {
      const { feature_web, ...rest } = systemFlags.data;
      set({
        systemFlags: rest,
        featureFlags: Array.isArray(feature_web) ? feature_web : [],
      });
    }
  },
  checkIsInMaintenance: () => {
    const {
      systemFlags: { maintenance },
    } = get();

    if (maintenance) {
      const start = new Date(maintenance.from);
      const end = new Date(maintenance.to);
      return isWithinInterval(new Date(), {
        start,
        end,
      });
    }

    return false;
  },
}));
