import {
  deleteCookie,
  SchemaValidationError,
  StrictUnion,
} from '@toggle/helpers';
import { v4 } from 'uuid';
import wretch, { Wretch } from 'wretch';
import { WretchError } from 'wretch/resolver';
import { ZodSchema } from 'zod';

import { config } from '~/config';
import { resetStores } from '~/stores/create-store/createStore';
import { useAuthStore } from '~/stores/use-auth/store/authStore';
import { authEndpoint } from '~/views/auth/services/auth-services';

import { httpCode } from './httpCode';
import { validateResponseSchema } from './utils/validateResponseSchema';

type HttpMethod = 'get' | 'post' | 'delete' | 'put';

type ApiFetchOptions<T> = {
  basePath?: string;
  method?: HttpMethod;
  body?: object | string;
  noToken?: boolean;
  schema?: ZodSchema<T>;
  headers?: Record<string, string>;
  options?: Record<string, string | AbortSignal | undefined>;
  signal?: AbortSignal;
};

type HandleRequestMethodProps = {
  method: HttpMethod;
  request: Wretch<unknown, unknown, undefined>;
  url: string;
  body?: object | string;
};

const handleRequestMethod = ({
  method,
  request,
  url,
  body,
}: HandleRequestMethodProps) => {
  if (method === 'post') {
    return request.post(body, url);
  }

  if (method === 'put') {
    return request.put(body, url);
  }

  if (method === 'delete') {
    return request.delete(url);
  }

  return request.get(url);
};

const { appSessionCookieAuth, api } = config;
export type ApiFetchResponse<T> = StrictUnion<
  | {
      validationError: SchemaValidationError | false;
      data: T;
    }
  | {
      error: WretchError;
      validationError: false;
    }
>;

export const apiFetch = async <T>(
  url: string,
  options?: ApiFetchOptions<T>
): Promise<ApiFetchResponse<T>> => {
  try {
    const data = await wretchRequest(url, options);

    return {
      data,
      validationError: false,
    };
  } catch (error) {
    if (error instanceof WretchError) {
      return { error, validationError: false };
    }

    return { error: new WretchError(), validationError: false };
  }
};

export const wretchRequest = async <T>(
  url: string,
  options?: ApiFetchOptions<T>
): Promise<T> => {
  const basePath = options?.basePath ?? api.root;
  const method = options?.method ?? 'get';

  const { getState, setState } = useAuthStore;
  const { token, refreshToken, destroy } = getState();

  const handleLocked = () => {
    setState({ isLocked: true });
  };

  const logUserOut = () => {
    deleteCookie(appSessionCookieAuth);
    resetStores();
    destroy();
    wretch(`${api.authRoot}${authEndpoint.logout}`)
      .options({ credentials: 'include' })
      .post();
  };

  const request = (() => {
    const wretchInstance = wretch(basePath, {
      mode: 'cors',
      headers: {
        'X-Request-ID': v4(),
        'Germ-Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone,
        ...options?.headers,
      },
      ...options?.options,
    });

    return options?.noToken
      ? wretchInstance
      : wretchInstance.auth(`Bearer ${token}`);
  })();

  return await handleRequestMethod({
    request,
    url,
    method,
    body: options?.body,
  })
    .unauthorized(error => {
      logUserOut();
      throw error;
    })
    .error(httpCode.upgradeRequired, error => {
      refreshToken();
      throw error;
    })
    .error(httpCode.locked, error => {
      handleLocked();
      throw error;
    })
    .res(response => {
      const contentType = response.headers.get('content-type');

      if (contentType === null) {
        return response;
      }

      if (contentType.includes('application/json')) {
        return response.json();
      }

      if (contentType.includes('text')) {
        return response.text();
      }

      if (contentType.includes('application/pdf')) {
        return response.blob();
      }

      return response;
    })
    .then((data: T) => {
      if (options?.schema) {
        const validationError = validateResponseSchema({
          data,
          endpoint: url,
          schema: options.schema,
        });

        if (validationError) {
          throw validationError;
        }
      }

      return data;
    });
};
