import { useEffect } from 'react';

import { isPathMatches } from '~/components/sidebar/utils/navigation-utils';
import { useAuthStore } from '~/stores/use-auth/store/authStore';
import { useNotifications } from '~/stores/use-notifications/useNotifications';
import { useChat } from '~/views/reasoner/use-chat/useChat';
import { useNotificationToast } from '~/views/reasoner/use-notification-toast/useNotificationToast';
import { usePortfolio } from '~/views/settings/portfolio-view/use-portfolio/usePortfolio';

const faviconLinks = {
  default: '/app/static/images/brand/favicon.svg',
  notification: '/app/static/images/brand/favicon-notification.svg',
};

export const useNotificationsListener = () => {
  const token = useAuthStore(s => s.token);
  const handlePortfolioWebsocketResponse = usePortfolio(
    state => state.handlePortfolioWebsocketResponse
  );

  const { showNotificationToast } = useNotificationToast();
  const { subscribeToMessageOfType, unsubscribe } = useNotifications(state => ({
    subscribeToMessageOfType: state.subscribeToMessageOfType,
    unsubscribe: state.unsubscribe,
  }));
  const { getMessage, markConversationReadStatus } = useChat(state => ({
    getMessage: state.getMessage,
    markConversationReadStatus: state.markConversationReadStatus,
  }));

  const toggleFavicon = (key: keyof typeof faviconLinks) => {
    const faviconElement = document.querySelector("link[rel*='icon']");
    faviconElement?.setAttribute('href', faviconLinks[key]);
  };

  useEffect(() => {
    return useChat.subscribe(state => {
      const unreadCount = state.getUnreadConversations().length;
      toggleFavicon(unreadCount ? 'notification' : 'default');
      const supportsBadgesApi = navigator && 'setAppBadge' in navigator;

      if (supportsBadgesApi) {
        unreadCount
          ? navigator.setAppBadge(unreadCount)
          : navigator.clearAppBadge();
      }
    });
  }, []);

  useEffect(() => {
    if (!token) {
      return undefined;
    }

    subscribeToMessageOfType('alfred', async response => {
      await getMessage(
        response.metadata.conversation_id,
        response.metadata.message_id
      );

      if (response.event.action === 'message.success') {
        const isAlfredPage = isPathMatches(['alfred']);

        if (!isAlfredPage) {
          showNotificationToast({
            content: response.body,
            conversationId: response.metadata.conversation_id,
            messageId: response.metadata.message_id,
          });
        }

        markConversationReadStatus(
          response.metadata.conversation_id,
          isAlfredPage
        );
      }
    });

    subscribeToMessageOfType('portfolio', async response => {
      handlePortfolioWebsocketResponse(response);
    });

    return unsubscribe;
  }, [token]);
};
