import { AlertType, Icon } from '@toggle/design-system';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useNavigateAlfred } from '~/hooks/use-navigate-alfred/useNavigateAlfred';
import { ToastPosition, useToasts } from '~/stores/use-toasts/useToasts';

import * as S from '../Reasoner.styles';
import { useChat } from '../use-chat/useChat';

export const CONVERSATION_TOAST = 'CONVERSATION_TOAST';

export interface ToastContent {
  content: string;
  conversationId: string;
  messageId: string;
}

export const useNotificationToast = () => {
  const { t } = useTranslation(['chat']);
  const navigateToAlfred = useNavigateAlfred();

  const { showToast } = useToasts(state => ({
    showToast: state.showToast,
  }));

  const showNotificationToast = ({
    content,
    conversationId,
    messageId,
  }: ToastContent) => {
    showToast({
      id: `conversation-toast-${messageId}`,
      hideToast: true,
      position: ToastPosition.TopRight,
      content: (
        <S.StyledAlert
          data-testid="notification-toast"
          type={AlertType.Info}
          title={t('chat:notification.title')}
          message={<S.Content>{content}</S.Content>}
          onClose={() => {}}
          withIcon
          customIcon={
            <Icon
              fillColor="var(--icon-primary)"
              iconName="AlfredNav"
              size={20}
            />
          }
          primaryBtn={{
            label: t('chat:notification.goToChat'),
            onClick: () => {
              useChat.setState({
                conversationId,
              });
              navigateToAlfred('reasoner');
            },
          }}
        />
      ),
      scope: CONVERSATION_TOAST,
    });
  };

  return { showNotificationToast };
};
