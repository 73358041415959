import { Indicator, useWindowResize } from '@toggle/helpers';
import * as d3 from 'd3';
import React, { FC, useMemo, useRef } from 'react';

import { ColorScheme } from '~/design-tokens/themes/themes';

import {
  InvertedThresholdArea,
  Variant,
} from './components/InvertedThresholdArea/InvertedThresholdArea';
import { ThresholdIconsOverlay } from './components/ThresholdIconsOverlay/ThresholdIconsOverlay';
import * as S from './TadpoleChart.styles';

export interface IndexedTsPoint {
  index: number;
  value: number;
  date: Date;
}

export interface TadpoleChartProps {
  tsData: IndexedTsPoint[];
  upperBound: number;
  lowerBound: number;
  midValue: number;
  thresholdVariants: [Variant, Variant];
  indicator: Indicator;
  colorScheme?: ColorScheme;
  isShowBoundNumbers?: boolean;
}

export const TadpoleChart: FC<TadpoleChartProps> = ({
  tsData,
  upperBound,
  lowerBound,
  thresholdVariants,
  midValue,
  indicator,
  colorScheme,
  isShowBoundNumbers = false,
}) => {
  const targetRef = useRef(null);
  const { width, height } = useWindowResize({ targetRef });

  const yScale = useMemo(() => {
    const [minY = 0, maxY = 0] = d3.extent(tsData.map(p => p.index));
    const yAxisPadding = Math.max((maxY - minY) * 0.25, 1e-6);
    return d3
      .scaleLinear()
      .domain([
        tsData[0]?.index - yAxisPadding,
        tsData[tsData.length - 1]?.index + yAxisPadding,
      ])
      .range([height, 0]);
  }, [tsData, height]);

  const xScale = useMemo(() => {
    const [minTs = 0, maxTs = 0] = d3.extent(tsData.map(p => p.value));
    const [minX = 0, maxX = 0] = [
      Math.min(lowerBound, minTs),
      Math.max(upperBound, maxTs),
    ];
    const xValPadding = Math.max((maxX - minX) * 0.25, 1e-6);
    const extendedXDomain = [minX - xValPadding, maxX + xValPadding];
    const adjustedRange =
      thresholdVariants[1] === 'bearish' ? [0, width] : [width, 0];
    return d3.scaleLinear().domain(extendedXDomain).range(adjustedRange);
  }, [tsData, width]);

  const plotLine = useMemo(
    () =>
      d3
        .line<typeof tsData[0]>()
        .y(p => yScale(p.index))
        .x(p => xScale(p.value))(tsData) ?? '',
    [yScale, xScale, tsData]
  );

  const maxValue = d3.max(tsData, d => d.value);

  if (maxValue === undefined) {
    return null;
  }

  const [leftValue, rightValue] =
    thresholdVariants[1] === 'bearish'
      ? [lowerBound, upperBound]
      : [upperBound, lowerBound];
  const midLineOffset = xScale(midValue);
  const toUpperBound = xScale(rightValue);
  const toLowerBound = xScale(leftValue);
  const bullishAreaWidth = Math.max(
    toLowerBound,
    Math.max(...xScale.range()) - toUpperBound
  );
  const bearishAreaWidth = toLowerBound;

  return (
    <section>
      {isShowBoundNumbers && (
        <S.SvgBounds>
          <text x={toLowerBound - 3} y={10} fill="var(--viz-bearish)">
            5
          </text>
          <text x={midLineOffset - 5} y={10} fill="var(--text-soft)">
            50
          </text>
          <text x={toUpperBound - 5} y={10} fill="var(--viz-bullish)">
            75
          </text>
        </S.SvgBounds>
      )}
      <S.SvgChart data-testid="inverted-threshold-chart" ref={targetRef}>
        <S.RectBackground />

        <S.ZeroLine
          data-testid="zero-line"
          y1="0"
          x1={midLineOffset}
          y2="100%"
          x2={midLineOffset}
        />

        {tsData.map((data, index) => {
          const position = yScale(data.index);

          return (
            <line
              key={index}
              x1="0"
              y1={position}
              x2="100%"
              y2={position}
              stroke="var(--border-soft)"
              strokeWidth="0.5"
            />
          );
        })}

        <S.PathPlot data-testid="plotted-path" d={plotLine} />

        <InvertedThresholdArea
          width={bearishAreaWidth}
          edgeOffset={toLowerBound}
          variant="bearish"
          colorScheme={colorScheme}
        />

        <InvertedThresholdArea
          width={bullishAreaWidth}
          offset={toUpperBound}
          variant="bullish"
          colorScheme={colorScheme}
        />

        <ThresholdIconsOverlay
          xScale={xScale}
          yScale={yScale}
          ts={tsData}
          lowerBound={lowerBound}
          upperBound={upperBound}
          indicator={indicator}
        />
      </S.SvgChart>
    </section>
  );
};
